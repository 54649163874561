<template>
    <div>
        <div class="data">
            <div class="title">
                {{ title.active }}
            </div>
            <el-table
                stripe
                border
                :data="page.data"
                style="width: 100%">
                <el-table-column
                    label="标题"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-home"></i>
                        <el-link @click="jump(scope.$index,scope.row)" type="primary">{{ scope.row.title }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核情况"
                    width="100"
                >
                    <template slot-scope="scope">
                        {{ reStoreKeyValue('review_status', scope.row.review_status) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建人"
                    width="180">
                    <template slot-scope="scope">
                        {{ scope.row.username && scope.row.username.nickname }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="创建时间"
                    width="180">
                    <template slot-scope="scope">
                        <format-time :time="scope.row.create_time" format="Y-m-d H:i:s"></format-time>
                    </template>
                </el-table-column>
                <!--                <el-table-column-->
                <!--                    label="操作"-->
                <!--                    width="90">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-button size="mini" @click="removeAnswer(scope.$index,scope.row)" icon="el-icon-delete"-->
                <!--                                   type="danger"></el-button>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                background
                @current-change="pageChange"
                :current-page.sync="search.pageNumber"
                layout="total,prev, pager, next"
                :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import F from '@/fields/fields';
import FormatTime from "@/components/common/format-time.vue";
export default {
    components: {
        FormatTime
    },
    computed: {
        showFields: function () {
            return F.get('answer', 'verify').fields;
        },
    },
    data() {
        return {
            baseUrl: '/bbs/answer/my',
            baseAnswerUrl: '/bbs/answer/myAnswer',
            title: {
                active: '',
                mapping: {
                    answer: '我回答的(查看最近一个月的回复内容，更多请联系管理员)',
                    draft: '我的草稿箱',
                    my: '我发布的'
                },
            },
            page: {
                data: [],
                total: 1,
            },
            search: {
                search: {},
                pageNumber: 1,
                type: '',
            }
        }
    },
    mounted() {
        this.getData();
    },
    watch: {
        '$route.params.type': function () {
            this.page.total = 0;
            this.search.pageNumber = 1;
            this.getData();
        }
    },
    methods: {
        reStoreKeyValue: function (key, value) {
            return this.$tools.reStoreKeyValue(key, value);
        },
        pageChange: function () {
            this.getData();
        },
        getData: function () {
            this.search.type = this.$route.params.type || '';
            this.title.active = this.title.mapping[this.search.type] || '等审核数据';
            let url = this.baseUrl;
            switch (this.search.type) {
                case 'answer':
                    //我回答的
                    this.search.search.review_status = '';
                    url = this.baseAnswerUrl;
                    break;
                case 'draft':
                    this.search.search.review_status = 1;
                    break;
                default:
                    this.search.search.review_status = '';
            }
            this.$bbs.get(url, {
                params: this.search,
            }).then(data => {
                this.page.total = data.data.total;
                if (this.search.type === 'answer') {
                    this.page.data = data.data.data.map(v => {
                        v.title = v.content && this.$tools.stripHtmlTag(v.content) || '';
                        if (v.title.length > 50) {
                            v.title = v.title.substr(0, 50) + '...';
                        }
                        v.username = v.from_username;
                        return v;
                    });
                } else {
                    this.page.data = data.data.data;
                }
                // this.page.currentPage = data.data.pageNumber;
            });
        },
        removeAnswer(index, data) {
            let title = data.title;
            if (title > 10) {
                title = title.substr(0, 10) + '...';
            }
            this.$confirm('你真的要删除:' + title + '?', '操作确认').then(() => {
                let url, saveData = {};
                switch (this.search.type) {
                    case 'answer':
                        url = this.baseUrl;
                        saveData = {answer_id: data.answer_id};
                        break;
                    case 'reply':
                        url = this.baseReplyUrl;
                        saveData = {reply_id: data.reply_id};
                        break;
                    case 'comment':
                        url = this.baseCommentUrl;
                        saveData = {comment_id: data.comment_id};
                        break;
                }
                this.$bbs.delete(url, {data: saveData, need: {error: true}}).then(() => {
                    this.page.data.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        },
        jump(index, data) {
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = '/wenda/detail/' + data.answer_id + '.html';
            a.click();
        }
    },
    beforeDestroy() {
        clearInterval(this.checkTimer);
    }
}

</script>
<style lang="less" scoped>
.data {
    margin-top: 1.5rem;

    .title {
        display: flex;
        background-color: #fff;
        font-weight: 600;
        padding: 10px;
        margin-bottom: 10px;
    }

    .jump-link {
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 5px 0;

        .index {
            text-align: center;
            width: 60px;
            flex-shrink: 0;
        }
    }
}

.page {
    background-color: #fff;
    padding: 10px 0;
    margin-top: 10px;
}
</style>
